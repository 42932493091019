import classes from "./style.module.css";
import LogoImg from "app/assets/icon/pile.png";
const LoaderScreen = () => {
  return (
    <div className={classes.loaderScreen}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          cursor: "pointer",
        }}
      >
        <img
          src={LogoImg}
          width={36}
          style={{ borderRadius: "4px" }}
          alt="logo"
        />
        <p
          style={{
            color: "#f28c28",
            textShadow: "1px 1px rgb(117, 76, 0)",
            fontSize: "48px",
            fontWeight: "bold",
          }}
        >
          Piles Capacity
        </p>
      </div>
      <p className={classes.loaderInfo}>
        The application is starting, please wait
      </p>
      <span className={classes.loader}></span>
    </div>
  );
};
export default LoaderScreen;
