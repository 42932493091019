import { APPLE_MAP_SEARCH_URL, APPLE_MAP_GEOCODE_URL } from "./constants";
import {
  AppleMapServiceResponse,
  AppleMapServiceVariables,
  AppleMapGeocodeServiceResponse,
  AppleMapGeocodeServiceVariables,
} from "./types";

export const searchLocation = ({
  params,
}: AppleMapServiceVariables): Promise<AppleMapServiceResponse> => {
  return fetch(APPLE_MAP_SEARCH_URL(params.search), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${params.token}`,
    },
  });
};

export const reverseGeocode = ({
  params,
}: AppleMapGeocodeServiceVariables): Promise<AppleMapGeocodeServiceResponse> => {
  return fetch(APPLE_MAP_GEOCODE_URL(params.loc), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${params.token}`,
    },
  });
};
