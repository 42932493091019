import { NavigateFunction } from "react-router-dom";
import { ProjectModel } from "services/webapp/models";

export const calloutForLandmarkAnnotation = (
  project: ProjectModel,
  navigate: NavigateFunction
) => {
  var popover = document.createElement("div");
  popover.className = "landmark";

  var title = popover.appendChild(document.createElement("h1"));
  title.className = "project_title";
  title.textContent = "at " + project.location.displayName;

  var section = popover.appendChild(document.createElement("section"));

  var link = section.appendChild(document.createElement("p"));
  link.className = "homepage";
  var a = link.appendChild(document.createElement("a"));
  a.className = "project_link";
  a.onclick = () => {
    navigate(`/project?p=${project.id}`);
    popover.style.display = "none"; // removing popover crashes the app since the maps is trying to remove it later on automatically when a new annotation is created, so we'll just hide it.
  };
  a.textContent = `Open Designs(${project?.design_count})<br>for ${project.name}`; // @Halil can we please add number of designs here - # is placeholder
  a.style.fontSize = "16px"; // Adjust this value as needed
  a.innerHTML = a.textContent;

  return popover;
};

var CALLOUT_OFFSET = new DOMPoint(-168, -78);

export const renderAnnotationCallout = (
  project: ProjectModel,
  navigate: NavigateFunction
) => {
  return {
    calloutElementForAnnotation: function () {
      return calloutForLandmarkAnnotation(project, navigate);
    },

    calloutAnchorOffsetForAnnotation: function () {
      return CALLOUT_OFFSET;
    },

    calloutAppearanceAnimationForAnnotation: function () {
      return ".4s cubic-bezier(0.4, 0, 0, 1.5) 0s 1 normal scale-and-fadein";
    },
  };
};
