import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import useAuthStore from "context/auth-store";
import MapLayout from "layout/Map";

import RouterList from "./Routes";
import LoaderScreen from "components/LoaderScreen";

const ProtectedRoute: React.FunctionComponent = () => {
  const accessToken = useAuthStore((state) => state.accessToken);

  useEffect(() => {}, [accessToken]);
  return accessToken ? <Outlet /> : <Navigate to="/" />;
};

const RouterMapProvider: React.FunctionComponent = () => {
  const protectedRouters = RouterList.filter(({ security }) => {
    return security === "protected";
  });

  const publicRouters = RouterList.filter(({ security }) => {
    return security === "public";
  });

  return (
    <Suspense fallback={<LoaderScreen />}>
      <Routes>
        <Route element={<ProtectedRoute />}>
          {protectedRouters.map(({ Page, path, layout }) => {
            return (
              <Route
                path={path}
                key={path}
                element={
                  layout ? (
                    <MapLayout>
                      <Page />
                    </MapLayout>
                  ) : (
                    <Page />
                  )
                }
              />
            );
          })}
        </Route>

        {publicRouters.map(({ Page, path }) => {
          return <Route path={path} key={path} element={<Page />} />;
        })}
      </Routes>
    </Suspense>
  );
};

export default RouterMapProvider;
