import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { createTokenMapkit } from "services/webapp/mapkit/endpoints";
import useAuthStore from "context/auth-store";

import MapContext from "./Context";
import ErrorPage from "components/ErrorScreen";

const MapProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { accessToken } = useAuthStore();

  const { data } = useQuery({
    queryKey: ["mapkit"],
    queryFn: () => createTokenMapkit({}),
    enabled: accessToken ? true : false,
  });

  const token = data?.data;
  const [map, setMap] = useState<mapkit.Map | null>(null);
  const element = useRef<HTMLDivElement>(null);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js";
  //   script.async = true;
  //   script.crossOrigin = "anonymous";
  //   script.setAttribute("data-callback", "initMapKit");
  //   script.setAttribute("data-libraries", "map");

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  useEffect(() => {
    try {
      if (!window?.mapkit) {
        const checkService = setInterval(() => {
          if (token && window?.mapkit) {
            window.mapkit.init({
              authorizationCallback: (done) => {
                done(token);
                setLoader(false);
              },
            });
            clearInterval(checkService);
          }
        }, 100);
      } else {
        if (token)
          window.mapkit.init({
            authorizationCallback: (done) => {
              done(token);
              setLoader(false);
            },
          });
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, loader]);

  // useEffect(() => {
  //   if (token && !exist) {
  //     if (window?.mapkit) {
  //       mapkit.init({
  //         authorizationCallback: (done) => {
  //           done(token);
  //           setExist(true);
  //         },
  //       });
  //     } else {
  //       setTimeout(() => {
  //         setReRender(!reRender);
  //       }, 0);
  //     }
  //   }
  // }, [token, exist, reRender]);

  const renderMap = () => {
    if (window?.mapkit) {
      const mapView = new mapkit.Map(element.current!);
      mapView.mapType = "standard";
      setMap(mapView);
    } else {
      setError(true);
    }
  };

  return (
    <MapContext.Provider
      value={{ map: map, mapRef: element, renderMap: renderMap }}
    >
      {error && <ErrorPage />}
      {!error && children}
    </MapContext.Provider>
  );
};
export default MapProvider;
