import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, Flip } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import ThemeProvider from "./Theme/ThemeProvider";
import MapProvider from "./Map/MapProvider";
const Provider: React.FunctionComponent<{ children: React.ReactNode }> = ({
  children,
}) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <MapProvider>
          <ThemeProvider>
            {children}
            <ToastContainer
              position="top-right"
              autoClose={3000}
              limit={3}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              transition={Flip}
            />
          </ThemeProvider>
        </MapProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default Provider;
