import classes from "./style.module.css";

const ErrorPage = () => {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className={classes.error_container}>
      <h1 className={classes.error_title}>Oops! Something went wrong.</h1>
      <p className={classes.error_message}>
        We’re sorry for the inconvenience. Please refresh the app.
      </p>
      <button className={classes.error_button} onClick={handleRefresh}>
        Refresh the application
      </button>
    </div>
  );
};

export default ErrorPage;
